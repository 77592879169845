<template>
  <div class="screen">
      <div class="keyboard">
          <div class="display">
              <div class="col-span-2 text-center text-xl" @click="goToSettings()">
                  ⚙️
              </div>
              <input
                v-model="number"
                type="text"
                inputmode="none"
                class="col-span-5 text-right text-4xl bg-transparent tracking-wider select-auto focus:outline-none"
              />
              <div @click="delNum()" class="col-span-2 text-center text-xl">
                  ⬅️
              </div>
          </div>
          <button @click="enterNum(7)">
              <span>7</span>
          </button>
          <button @click="enterNum(8)">
              <span>8</span>
          </button>
          <button @click="enterNum(9)">
              <span>9</span>
          </button>
          <button @click="clearNum()">
              <span class="text-xl">Delete</span>
          </button>
          <button @click="enterNum(4)">
              <span>4</span>
          </button>
          <button @click="enterNum(5)">
              <span>5</span>
          </button>
          <button @click="enterNum(6)">
              <span>6</span>
          </button>
          <button>
              <span></span>
          </button>
          <button @click="enterNum(1)">
              <span>1</span>
          </button>
          <button @click="enterNum(2)">
              <span>2</span>
          </button>
          <button @click="enterNum(3)">
              <span>3</span>
          </button>
          <button class="row-span-3" @click="save()">
              <span class="text-xl">Save</span>
          </button>
          <button @click="enterNum(0)" class="col-span-2">
              <span>0</span>
          </button>
          <button @click="goToList">
              <span class="text-xl">List</span>
          </button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'MainScreen',
  data:  function(){
    return {
      number: "",
    }
  },
  methods:{
    enterNum(value){
      this.number=this.number+value
    },
    delNum(){
      this.number=this.number.slice(0, -1)
    },
    clearNum(){
      this.number = ""
    },
    save(){
      var currenttme = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
      this.eventBus.emit('saveItem', {number: this.number, time: currenttme})
      this.clearNum()
    },
    goToList(){
      this.eventBus.emit('goToList')
    },
    goToSettings(){
      this.eventBus.emit('goToSettings')
    },
  },
  props: {

  }
}
</script>
