<template>
  <div class="">
    <div @click="goToMain"  class="text-white">back</div>
  <table class="border-collapse border border-slate-400 w-full text-wite bg-gray-900">
    <thead>
    <tr>
      <th class="text-white">#</th>
      <th class="text-white">time</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(result,i) in results" :key="i">
       <th scope="row" class="border text-white p-3">{{ result.number  }}</th>
       <td class="text-white border">{{ result.time }}</td>
    </tr>
    </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'ResultScreen',
  data:  function(){
    return {

    }
  },
  mounted () {

  },
  methods:{
    goToMain(){
      this.eventBus.emit('goToMain')
    },

  },
  props: {
    results: Array
  }

}
</script>
