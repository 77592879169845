<template>
  <div class="">
    <div @click="goToMain"  class="text-white m-4"> Back</div>

  <div class="flex items-center justify-center h-screen bg-red-100">

        <div class="bg-white rounded-2xl border shadow-x1 p-10 max-w-lg">
          <div class="flex flex-col items-center space-y-4">
            <h1 class="font-bold text-2xl text-gray-700 w-4/6 text-center">
              Settings
            </h1>
            <label for="competition_alias">Your name</label>
            <input
              type="text"
              placeholder="watcher"
              class="border-2 rounded-lg w-full h-12 px-4"
              v-model="watcherName"
            />
            <label for="competition_alias">Post title</label>
            <input
              type="text"
              placeholder="post"
              class="border-2 rounded-lg w-full h-12 px-4"
              v-model="postTitle"
            />
            <label for="competition_alias">Competition</label>
            <input
              type="text"
              placeholder="competition code"
              class="border-2 rounded-lg w-full h-12 px-4"
              v-model="competitionAlias"
            />
            <label for="competition_alias">Unique identificator</label>
            <input
              type="text"
              placeholder="competition_alias"
              class="border-2 rounded-lg w-full h-12 px-4"
              v-model="myUniqueIdentificator"
              readonly="readonly"
            />
            <button
              @click="save"
              class="bg-red-400 text-white rounded-md hover:bg-red-500 font-semibold px-4 py-3 w-full"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<!-- https://athletex.kz/api/jzyBvT/get_events -->
<script>
import axios from 'axios'
export default {
  name: 'SettingsScreen',
  data:  function(){
    return {
      myUniqueIdentificator: null,
      watcherName: null,
      postTitle: null,
      competitionAlias: null,
      competitions: []
    }
  },
  mounted () {
    this.myUniqueIdentificator = this.settings.myUniqueIdentificator
    this.watcherName = this.settings.watcherName
    this.postTitle = this.settings.postTitle
    this.competitionAlias = this.settings.competitionAlias
    this.getEvents()
  },
  methods:{
    goToMain(){
      this.eventBus.emit('goToMain')
    },
    save(){
      this.eventBus.emit('saveSettings', {
                                            myUniqueIdentificator: this.myUniqueIdentificator,
                                            watcherName: this.watcherName,
                                            postTitle: this.postTitle,
                                            competitionAlias: this.competitionAlias,
                                          })
      this.clearNum()
    },
    getEvents(){

          axios
            .get('https://athletex.kz/api/jzyBvT/get_events')
            .then(response => {
              console.log(response);
              if(response.status == 200){
                console.log(">>>>>>>>>>>>>OK<<<<<<<<<<<<");
                response.data.forEach((item) => {
                  console.log(item.title);
                  this.competitions.push({text: item.title, value: item.alias})
                });

              }else{
                console.log(">>>>>>>>>>>>>"+response.status+"<<<<<<<<<<<<");
              }
            });

    }
  },
  props: {
    settings: Object
  }

}
</script>
