<template>
  <MainScreen v-if="showMainScreen" />
  <ResultScreen v-if="showResultScreen" :results=results />
  <SettingsScreen v-if="showSettingsScreen" :settings=settings />
</template>

<script>
import MainScreen from './components/MainScreen.vue'
import ResultScreen from './components/ResultScreen.vue'
import SettingsScreen from './components/SettingsScreen.vue'
import { uuid } from 'vue3-uuid';
import { useCookies } from "vue3-cookies";
import axios from 'axios'

function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('sportTimingDB', 1);
    
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('results', { keyPath: 'id', autoIncrement: true });
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

// Функция для добавления данных в IndexedDB
function addResult(result) {
  return openDatabase().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['results'], 'readwrite');
      const store = transaction.objectStore('results');
      const request = store.add(result);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  });
}

// Функция для получения данных из IndexedDB
function getResults() {
  return openDatabase().then((db) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['results'], 'readonly');
      const store = transaction.objectStore('results');
      const request = store.getAll();

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  });
}

// Обновленный компонент Vue
export default {
  name: 'App',
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data: function(){
    return {
      showMainScreen: true,
      showResultScreen: false,
      showSettingsScreen: false,
      results: [],
      info: null,
      settings: {
        myUniqueIdentificator: null,
        watcherName: null,
        postTitle: null,
        competitionAlias: null
      }
    }
  },
  mounted () {
    document.title = "DO! SportTiming App";
    this.getCookies();
    this.loadResultsFromIndexedDB();
    this.sendDataToServer();
    this.getResultDataFromServer();

    this.eventBus.on('goToList', () => {
      this.showResultScreen = true;
      this.showMainScreen = false;
      this.showSettingsScreen = false;
    });
    this.eventBus.on('goToMain', () => {
      this.showResultScreen = false;
      this.showMainScreen = true;
      this.showSettingsScreen = false;
    });
    this.eventBus.on('goToSettings', () => {
      this.showResultScreen = false;
      this.showMainScreen = false;
      this.showSettingsScreen = true;
    });

    this.eventBus.on('saveItem', (args) => {
      this.save(args);
    });

    this.eventBus.on('saveSettings', (args) => {
      this.saveSettings(args);
    });
  },
  components: {
    MainScreen, ResultScreen, SettingsScreen
  },
  methods: {
    save(args){
      const param = {
        number: args.number,
        time: args.time,
        watcher: this.settings.watcherName,
        post: this.settings.postTitle,
        competition_code: this.settings.competitionAlias,
        wid: this.settings.myUniqueIdentificator,
        sync: 0
      };
      this.results.push(param);
      addResult(param);
    },
    saveSettings(settings){
      this.cookies.set("watcherName", settings.watcherName, 60 * 60 * 24 * 90, "");
      this.cookies.set("postTitle", settings.postTitle, 60 * 60 * 24 * 2, "");
      this.cookies.set("competitionAlias", settings.competitionAlias, 60 * 60 * 24 * 2, "");
      this.getCookies();
    },
    getCookies(){
      this.settings.myUniqueIdentificator = this.cookies.get("uniqueIdentificator");
      if(this.settings.myUniqueIdentificator == null){
        this.settings.myUniqueIdentificator = uuid.v4();
        this.cookies.set("uniqueIdentificator", this.settings.myUniqueIdentificator);
      }
      this.settings.watcherName = this.cookies.get("watcherName");
      this.settings.postTitle = this.cookies.get("postTitle");
      this.settings.competitionAlias = this.cookies.get("competitionAlias");
    },
    loadResultsFromIndexedDB() {
      getResults().then((results) => {
        this.results = results;
      });
    },
    sendDataToServer() {
      setInterval(() => {
        if (this.results.length > 0){
           this.results.forEach((item, i) => {
             if (item.sync == 0) {
               axios
                 .get('https://manage.sporttiming.app/api/result/save', {params: item})
                 .then(response => {
                   if(response.status == 201){
                     this.results[i].sync = 1;
                   }
                 });
             }
           });
        }
      }, 15000);
    },
    getResultDataFromServer() {
      axios.get('https://manage.sporttiming.app/api/result/get/my', {params: {competition_code: this.settings.competitionAlias, wid: this.settings.myUniqueIdentificator}})
        .then(response => {
          if(response.status == 200){
              response.data.forEach((item) => {
                const param = {
                  number: item.number,
                  time: item.time,
                  watcher: this.settings.watcherName,
                  post: this.settings.postTitle,
                  competition_code: this.settings.competitionAlias,
                  me: this.settings.myUniqueIdentificator,
                  sync: 1
                };
                this.results.push(param);
                addResult(param);
              });
          }
      });
    }
  }
}
</script>

<style>
#app {
  text-align: center;
  margin-top: 60px;
}
  body {
  @apply bg-black overscroll-none;
}
  .screen {
  @apply h-screen  min-w-[300px] max-w-sm
  mx-auto flex items-center;
}

  .keyboard {
  @apply grid grid-cols-4 grid-rows-6 gap-1
  p-1 w-full bg-gray-900 h-96 font-bold rounded-lg
  ring-2 ring-white/20 shadow text-white select-none;
}

.keyboard .display {
  @apply col-span-4 row-span-2 bg-gray-900 rounded-lg
  grid grid-cols-9 gap-1 place-content-center
}

.keyboard button {
  @apply relative flex items-center justify-center text-3xl
  transition-all duration-500 rounded bg-slate-500
  active:scale-95 active:duration-[0s] active:bg-amber-500
  ;
}
</style>
